<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    class="filter"
    ref="form"
    :class="checkRTL"
  >
    <el-row>
      <!-- <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.id.label" :prop="fields.id.name">
          <el-input v-model="model[fields.id.name]"/>
        </el-form-item>
      </el-col>-->

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.keywords.label" :prop="fields.keywords.name">
          <el-input v-model="model[fields.keywords.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.createdById.label"
          :prop="fields.createdById.name"
          v-if="canListOtherQEs"
        >
          <app-autocomplete-one-input
            :fetchFn="fetchTeacherACwithLangs"
            v-model="model[fields.createdById.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.questionType.label" :prop="fields.questionType.name">
          <app-autocomplete-one-input
            :fetchFn="fields.questionType.fetchFn"
            v-model="model[fields.questionType.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col v-if="hasAdminRole" :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.questionLanguage.label" :prop="fields.questionLanguage.name">
          <app-autocomplete-one-input
            :fetchFn="fields.questionLanguage.fetchFn"
            v-model="model[fields.questionLanguage.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.questionGroups.label" :prop="fields.questionGroups.name">
          <app-autocomplete-many-input
            :fetchFn="fields.questionGroups.fetchFn"
            v-model="model[fields.questionGroups.name]"
          ></app-autocomplete-many-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.questionStatus.label"
          :prop="fields.questionStatus.name"
        >
          <el-select
            placeholder
            v-model="model[fields.questionStatus.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.questionStatus
                .options"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.section.label"
          :prop="fields.section.name"
        >
          <el-select
            placeholder
            v-model="model[fields.section.name]"
          >
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.section.options"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <div class="filter-buttons" :class="rtlAndPadding">
      <el-button :disabled="loading" @click="doFilter" icon="el-icon-fa-search" type="primary" native-type="submit">
       <span class="card-title"> <app-i18n code="common.search"></app-i18n> </span>
      </el-button>

      <el-button :disabled="loading" @click="doResetFilter" icon="el-icon-fa-undo">
       <span class="card-title"> <app-i18n code="common.reset"></app-i18n> </span>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { QuestionModel } from '@/modules/question/question-model';
import { IamService } from '../../iam/iam-service';

const { fields } = QuestionModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.keywords,
  fields.createdById,
  fields.questionType,
  fields.questionLanguage,
  fields.questionGroups,
  fields.questionStatus,
]);

export default {
  name: 'app-question-list-filter',
  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
      fetchTeacherACwithLangs: null,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'question/list/loading',
      filter: 'question/list/filter',
      hasAdminRole: 'auth/hasAdminRole',
      canListOtherQEs: 'auth/canListOtherQEs',
      currentUserLangs: 'auth/currentUserLangs',
      checkRTL: 'question/form/checkRTL',
      rtlAndPadding: 'question/form/rtlAndPadding'
    }),

    fields() {
      return fields;
    },
  },

  created() {
    //mounted'da calismaz
    this.fetchTeacherACwithLangs = IamService.fetchTeacherACwithLangs(
      this.currentUserLangs,
    );
  },
  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'question/list/doReset',
      doFetch: 'question/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      //console.log("Filtrele");
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      //console.log("Model: ", this.model);
      const filter = filterSchema.cast(this.model);
      //console.log("Filter: ", filter);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
