<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.question.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.question.list.title"
        ></app-i18n>
      </h1>

      <app-question-list-toolbar></app-question-list-toolbar>
      <app-question-list-filter></app-question-list-filter>
      <app-question-list-table></app-question-list-table>
    </div>
  </div>
</template>

<script>
import QuestionListFilter from '@/modules/question/components/question-list-filter.vue';
import QuestionListTable from '@/modules/question/components/question-list-table.vue';
import QuestionListToolbar from '@/modules/question/components/question-list-toolbar.vue';

export default {
  name: 'app-question-list-page',

  components: {
    [QuestionListFilter.name]: QuestionListFilter,
    [QuestionListTable.name]: QuestionListTable,
    [QuestionListToolbar.name]: QuestionListToolbar,
  },
};
</script>

<style></style>
